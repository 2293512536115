import React, { useEffect, useState } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import queryString from 'query-string';  // To parse query params

/* Tools */
import Skeleton from 'react-loading-skeleton';
import { IconMap } from '../config/IconMap';

/* Components */
import SaasMenu from '../components/SaasMenu/SaasMenu';
import Modal from '../components/public_components/productModal';
import PaymentModal from '../components/public_components/paymentModal';

export default function Hub({ previewMode, previewData }) {

  // User agent checks
  // const displaySaaSMenu = userAgent.indexOf('ourapp_');

  // States
  const { uid } = useParams();
  const location = useLocation();

  // App config
  const [metaData, setMetaData] = useState(null); // State to store configuration data
  const [styleGuide, setStyleGuide] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null);

  // Local storage objects 
  const recentActivity = localStorage.getItem(`recentActivity-${uid}`);
  const recentActivityJSON = JSON.parse(recentActivity);

  // Transitions
  const [fadeIn, setFadeIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUid, setSelectedUid] = useState(null); // Store the selected product uid

  // Carts
  const [cartItems, setCartItems] = useState([]);

  // Menu controls
  const [showBackButton, setShowBackButton] = useState(false);  // Manage back button visibility
  const [currentPage, setCurrentPage] = useState(`/a/${uid}`); // Track current page

  // Prompts
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showMore, setShowMore] = useState(false); // State to control "View More"

  // Function to handle success or failure modals
  const handlePaymentModal = (status) => {
    if (status === 'success') {
      setShowSuccessModal(true);
    } else {
      setShowErrorModal(true);
    }
  };
  // Function to reset modal state
  const resetModals = () => {
    setShowSuccessModal(false);
    setShowErrorModal(false);
  };

  // Clear cart
  const clearCart = () => setCartItems([]);

  // Use effect to call App config if not in preview editor
  useEffect(() => {
    // Fetch configuration data from the API when previewMode is false
    if (!previewMode) {
      fetch(`/api/get-app/${uid}`) // Replace :ID with the actual app ID
        .then((response) => response.json())
        .then((data) => {
          setMetaData(data);
          setIsLoading(false);
          if (data.style_guide) {
            setStyleGuide(data.style_guide);
          }
          // Check if background image URL is present
          if (data.background_image_url) {
            setBackgroundImage(data.background_image_url);
          }
        })
        .catch((error) => {
          console.error('Error fetching configuration data:', error);
          setIsLoading(true);
        });
    } else if (previewData && previewMode) {
      setIsLoading(false);
    }
  }, [uid]);

  // load preview data into state if in preview mode
  useEffect(() => {
    if (previewMode && previewData) {
      setMetaData(previewData);
      setIsLoading(false);
      if (previewData.style_guide) {
        setStyleGuide(previewData.style_guide);
      }
      // For preview mode
      if (previewData.background_image_url) {
        setBackgroundImage(previewData.background_image_url);
      }
    }
  }, [previewMode, previewData]);

  useEffect(() => {
    if (!isLoading) {
      setFadeIn(true);
    }
  }, [isLoading]);

  // Function to handle internal link click
  const handleInternalLinkClick = (destination) => {
    // Assuming uid is available in the current component scope
    // Append the `from` query param dynamically with the uid
    return `${destination}?from=${uid}`;
  };

  // Check if the `from` query parameter is set to 'hub'
  useEffect(() => {
    const queryParams = queryString.parse(location.search);  // Parse the query params

    // Only run the logic if the `from` parameter is present
    if (queryParams.from) {
      // If the `from` query param matches the current `uid`, hide the back button
      if (queryParams.from === uid) {
        setShowBackButton(false);  // Hide back button on original Hub page
      } else {
        setShowBackButton(true);  // Show back button when coming from another page
      }
    } else {
      setShowBackButton(false);  // Default: hide back button if `from` is not present
    }
  }, [location.search, uid]);
  
  // Function to handle "View More" click
  const handleShowMoreClick = () => {
    setShowMore(true); 
  };
  const handleShowLessClick = () => {
    setShowMore(false); 
  };

  // Open modal and set the selected product UID
  const openModal = (to) => {
    setSelectedUid(to); 
    setIsModalOpen(true); 
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUid(null); 
  };

  const addToCart = (newItem) => {
    setCartItems((prevItems) => {
      const existingItemIndex = prevItems.findIndex(item => item.sku === newItem.sku);
      if (existingItemIndex >= 0) {
        const updatedItems = [...prevItems];
        updatedItems[existingItemIndex].quantity += 1;
        return updatedItems;
      } else {
        const newCartItem = {
          ...newItem,
          quantity: 1,  
          uid: newItem.uid,
          price: newItem.price,
          title: newItem.title,
          image_url: newItem.image_url,
        };
        return [...prevItems, newCartItem];
      }
    });
  };

  // Function to update cart item quantity
  const updateCart = (sku, newQuantity) => {
    setCartItems((prevItems) => {
      return prevItems.map(item =>
        item.sku === sku ? { ...item, quantity: newQuantity } : item
      );
    });
  };

  // Function to remove an item from the cart
  const removeItem = (sku) => {
    setCartItems((prevItems) => prevItems.filter(item => item.sku !== sku));
  };

  return (
    <>
      <div className="min-h-full">
        <div className={`pb-32 headerContainer bg-gray-400 transition-opacity duration-1000 ease-in-out ${fadeIn ? 'opacity-100' : 'opacity-0'}`}
          style={{
            backgroundColor: styleGuide?.primary,
            backgroundImage: backgroundImage
              ? `linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.4)), url(${backgroundImage})`
              : null,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}>
          {!previewMode ? (
            <SaasMenu
              previewMode={previewMode}
              showBackButton={showBackButton}
              cartItems={cartItems} // Pass cartItems here
              updateCart={updateCart} // Pass updateCart function
              removeItem={removeItem} // Pass removeItem function
              clearCart={clearCart} // Clear cart passed down from checkout
              appUid={uid} // Pass UID as context
              handlePaymentModal={handlePaymentModal} // Shows confirmation
              styleGuide={styleGuide}
            />
          ) : (
            <header className="pb-10 pt-5"></header>
          )}
        </div>

        <main className="-mt-36 pb-6">
          <div className="mx-auto max-w-3xl lg:max-w-5xl">
            {!metaData ? (
              <div className="mt-4 mx-4 overflow-hidden rounded-lg">
                <Skeleton className="my-2" count={1} height={40} width={250} />
                <Skeleton className="my-2" count={1} height={40} width={150} />
              </div>
            ) : (
              <>
                <h1 className={`text-2xl font-bold tracking-tight text-white px-4 faded ${fadeIn && 'visible'}`}>{metaData?.title}</h1>
                <p className={`text-base text-white px-4 faded ${fadeIn && 'visible'}`}>{metaData?.description}</p>
              </>
            )
            }

            {/* Main 3 column grid */}
            <div className={`grid items-start gap-4 py-6 transition-all duration-500 ease-in-out ${recentActivity ? 'grid-cols-1 lg:grid-cols-3' : 'grid-cols-1'}`}>
              
              {/* Left column - dynamic content */}
              <div className={`grid grid-cols-1 gap-4 ${recentActivity ? 'lg:col-span-2' : ''}`}>

                {/* Tiles using JSON */}
                {!metaData ? (
                  <section className="mt-4 mx-4 overflow-hidden rounded-lg" >
                    <Skeleton className="my-2" count={1} height={40} width={150} />
                    <Skeleton className="rounded-lg mt-2 mb-4" count={2} height={100} />
                    <Skeleton className="mb-2 mt-4" count={1} height={40} width={150} />
                    <Skeleton className="rounded-lg my-2" count={2} height={100} />
                  </section>
                ) : (previewMode ? previewData?.app_config : metaData?.app_config)?.map((tile, index) => (
                  <section key={index} className="mt-4" aria-labelledby={`section-${index + 1}-title`}>
                    <h2 className="sr-only" id={`section-${index + 1}-title`}>
                      {tile.display_title}
                    </h2>
                    <p className={`text-base font-semibold tracking-tight cardHeader px-4 faded ${fadeIn && 'visible'}`}>{tile.display_title}</p>
                    {tile.type === 'action' && (
                      <div className={`mt-4 overflow-hidden rounded-lg bg-white shadow mx-4 faded ${fadeIn && 'visible'}`}>
                        <ul>
                          {tile.schema.map((object, index) => (
                            <Link key={index} to={!previewMode ? handleInternalLinkClick(object.to) : undefined} className="group block" target={object.option === 'external' ? "_blank" : undefined}>
                              <li>
                                <div className="flex items-center px-3 py-3">
                                  <div className="flex min-w-0 flex-1 items-center">
                                    <div className="min-w-0 flex-1 md:gap-4">
                                      <div>
                                        <p className="truncate text-sm font-medium text-gray-900">{object.name}</p>
                                        <p className="mt-2 flex items-center text-sm text-gray-500">
                                          <span className="truncate">{object.description}</span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    {object.option === 'external' ? (
                                      React.createElement(IconMap['ArrowTopRightOnSquareIcon'], { className: 'h-5 w-5 text-gray-400 group-hover:text-gray-700', 'aria-hidden': 'true' })
                                    ) : (
                                      React.createElement(IconMap['ChevronRightIcon'], { className: 'h-5 w-5 text-gray-400 group-hover:text-gray-700', 'aria-hidden': 'true' })
                                    )}
                                  </div>
                                </div>
                              </li>
                            </Link>
                          ))}
                        </ul>
                      </div>
                    )}
                    {tile.type === 'gallery' && (
                      <div className="relative mt-4">
                        <div className="relative w-full overflow-x-auto no-scrollbar">
                          <ul id="gallery" role="list" className="inline-flex space-x-8 lg:grid lg:grid-cols-3 lg:gap-x-2 lg:space-x-0">
                            {tile.schema.map((object, index) => (
                              <li key={index} className={`inline-flex w-60 flex-col text-center lg:w-auto faded ${fadeIn && 'visible'}`} onClick={() => openModal(object.to)}>
                                <div className="group relative">
                                  <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200">
                                    <img
                                      src={object.background_image_url}
                                      alt="Alt"
                                      className="h-full w-full object-cover object-center group-hover:opacity-75"
                                    />
                                  </div>
                                  <div className="mt-4">
                                    <h3 className="mt-1 text-gray-900 text-sm font-medium">
                                      <span className="absolute inset-0" />
                                      {object.name}
                                    </h3>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                  </section>
                ))}
              </div>

              {/* 
              {
                "config": [
                  {
                    "id": "1",
                    "type": "submission",
                    "date": "2023-01-01"
                  },
                  {
                    "id": "2",
                    "type": "other",
                    "date": "2023-01-02"
                  }
                ]
              }
              */}

              {!previewMode && (
                <div className="grid grid-cols-1 gap-4">
                  <section className="mt-4" aria-labelledby="section-2-title">
                    {recentActivity && (
                      <>
                        <p className={`tracking-tight text-base sm:text-black lg:text-white cardHeader-black font-semibold mx-4 faded ${fadeIn && 'visible'}`}>Recent purchases</p>
                        <h2 className="sr-only" id="section-2-title">
                          Your recent activity sorted by latest activities
                        </h2>
                        <div className={`mt-4 mx-4 overflow-hidden rounded-lg bg-white shadow faded ${fadeIn && 'visible'}`}>
                          {/* Check if recent activity data is available */}
                          {recentActivity && recentActivityJSON && (
                            <ul>
                              {recentActivityJSON.config
                                .slice(0, showMore ? recentActivityJSON.config.length : 1) // Show all if "showMore" is true, otherwise show only the first 2
                                .map((activity, index) => (
                                  <Link key={index} to={"/form/" + activity.id}>
                                    <li className="group block">
                                      <div className="flex items-center px-3 py-3">
                                        <div className="flex min-w-0 flex-1 items-center">
                                          <div className="min-w-0 flex-1 md:gap-4">
                                            <div>
                                              <p className="truncate text-sm font-medium text-gray-900">
                                                {activity.type === 'submission' ? 'Form submission' : 'Other'}
                                              </p>
                                              <p className="mt-2 flex items-center text-sm text-gray-500">
                                                <span className="truncate">{activity.date}</span>
                                              </p>
                                            </div>
                                          </div>
                                          {(
                                            React.createElement(IconMap['ChevronRightIcon'], { className: 'h-5 w-5 text-gray-400 group-hover:text-gray-700', 'aria-hidden': 'true' })
                                          )}
                                        </div>
                                      </div>
                                    </li>
                                  </Link>
                                ))}
                            </ul>
                          )}
                        </div>
                        {/* "View More" Button */}
                        {recentActivityJSON?.config.length > 1 && !showMore && (
                          <div className="mt-2 mx-4 flex justify-center">
                            <button
                              onClick={handleShowMoreClick}
                              className="text-sm text-gray-600 py-2 hover:text-gray-800 transition"
                            >
                              View more
                            </button>
                          </div>
                        )}

                        {showMore && (
                          <div className="mt-2 mx-4 flex justify-center">
                            <button
                              onClick={handleShowLessClick}
                              className="text-sm text-gray-600 py-2 hover:text-gray-800 transition"
                            >
                              Show less
                            </button>
                          </div>
                        )}
                      </>
                    )}
                  </section>
                </div>
              )}
            </div>
          </div>
        </main>

        {/* Modal usage */}
        <Modal isOpen={isModalOpen} closeModal={closeModal} previewMode={previewMode} uid={selectedUid} addToCart={addToCart} styleGuide={styleGuide} />

        {showSuccessModal && <PaymentModal status="success" onClose={resetModals} />}
        {showErrorModal && <PaymentModal status="fail" onClose={resetModals} />}

      </div>
      {!previewMode && !isLoading && (
        <>
        <div className="text-gray-500 bg-white text-xs text-center fixed bottom-4 left-1/2 transform -translate-x-1/2 py-3 px-4 mt-3 rounded-full shadow-lg"><p>Powered by <Link to="https://ourapp.studio" target="_blank" className='underline text-indigo-600'>ourapp.studio</Link></p></div>
        </>
      )}

    </>
  )
}
