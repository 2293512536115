import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie'; 
import axios from 'axios';

/* Components */
import { editor_metadata } from '../config/config';
import UpgradeAccount from '../components/UpgradeAccount/UpgradeAccount';
import ProductGrid from '../components/ProductGrid/ProductGrid';
import Notification from '../components/Notifications/Notifications';

export default function VendorProfile() {
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(true);

    const [showNotification, setShowNotification] = useState(false);
    const triggerNotification = () => {
        setShowNotification(true);
        setTimeout(() => {
            setShowNotification(false); 
        }, 3000); 
    };

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = Cookies.get('auth_token');
                if (!token) {
                    throw new Error('No authentication token found');
                }                
                const response = await axios.get('/api/vendor-profile/', {
                    headers: {
                        Authorization: `Token ${token}`,  
                    },
                });

                setProfile(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching profile:", error);
                setLoading(false);
            }
        };

        fetchProfileData();
    }, []);

    if (loading) return <p>Loading...</p>;

    return (
        <>
            <main className="lg:pl-72">
                <div>
                    <div className="px-4 py-6 sm:px-6 lg:px-8 lg:py-6">
                        {/* Conditionally render based on vendor level */}
                        {profile.show_upgrade_form ? (
                            <UpgradeAccount context={editor_metadata.PRODUCT_EDIT} />
                        ) : (
                            <> 
                                <ProductGrid triggerNotification={triggerNotification} />
                                <Notification show={showNotification} />
                            </>
                        )}
                    </div>
                </div>
            </main>
        </>
    );
}
