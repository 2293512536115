import React from 'react';

export default function ReusableModal({
  isOpen,
  closeModal,
  title,
  fieldValues = {},
  setFieldValues,
  handleSave,
  isSaving,
  generalError,
  isTextArea = false,
  isDelete = false,
}) {
  if (!isOpen) return null;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFieldValues((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="fixed inset-0 z-10 flex items-center justify-center bg-gray-400 bg-opacity-75 transition-opacity" onClick={closeModal}>
      <div className="relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg" onClick={(e) => e.stopPropagation()}>
        <div className="bg-white p-6 shadow-lg">
          <h3 className="text-base font-semibold leading-6 text-gray-900">{title}</h3>
          <div className="mt-4">
            {!isDelete ? (
              Object.keys(fieldValues).map((field) => (
                <div key={field} className="mt-4">
                  <label className="block text-sm font-medium text-gray-700">{field.replace('_', ' ')}</label>
                  {isTextArea && field === 'description' ? (
                    <textarea
                      name={field}
                      value={fieldValues[field]}
                      onChange={handleInputChange}
                      className="mt-1 w-full p-2 border border-gray-300 rounded-md"
                      rows="5"
                    />
                  ) : (
                    <input
                      type="text"
                      name={field}
                      value={fieldValues[field]}
                      onChange={handleInputChange}
                      className="mt-1 w-full p-2 border border-gray-300 rounded-md"
                    />
                  )}
                </div>
              ))
            ) : (
              <p>Are you sure you want to delete this item?</p>
            )}
            {generalError && <p className="mt-2 text-sm text-red-600">{generalError}</p>}
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
          <button
            className={`inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 sm:ml-3 sm:w-auto ${isSaving ? 'cursor-not-allowed opacity-50' : ''}`}
            onClick={handleSave}
            disabled={isSaving}
          >
            {isDelete ? 'Confirm' : isSaving ? 'Saving...' : 'Save'}
          </button>

          <button
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            onClick={closeModal}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
