import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import Cookies from 'js-cookie';

// components
import CreateAside from '../Asides/CreateAside';
import { IconMap } from '../../config/IconMap';

export default function Apps({ triggerNotification }) {
  const [apps, setApps] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCreateAppDialogOpen, setIsCreateAppDialogOpen] = useState(false);
  const authToken = Cookies.get('auth_token');

  // Define the fetchApps function outside of useEffect
  const fetchApps = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('/api/apps', {
        method: 'GET',
        headers: {
          'Authorization': `Token ${authToken}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setApps(data);  // Update the state with the fetched apps
        console.log(data);
      } else {
        console.error('Request failed with status:', response.status);
        const errorData = await response.json();
        console.error('Error response:', errorData);
      }

      setIsLoading(false); 
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleAppCreate = async () => {
    await fetchApps();
    triggerNotification();
  };

  useEffect(() => {
      fetchApps(); 
  }, [authToken]);

  // Function to handle closing the CreateAppAside dialog
  const closeCreateAppDialog = () => {
    setIsCreateAppDialogOpen(false);
  };

  return (
    <div>
      <h1 className="text-base font-semibold text-gray-900">Apps</h1>
      {isLoading ? (
        <div className="flex">
          <Skeleton className="my-2 rounded-lg" count={1} height={144} width={378} />
          <Skeleton className="my-2 rounded-lg ml-4" count={1} height={144} width={378} />
        </div>
      ) : (
        <dl className="mt-4 grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
          {apps.map((app) => (
            <Link to={`/m/${app.uid}`} key={app.uid}>
              <div className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow hover:shadow-md sm:px-6 sm:pt-6 h-full">
                <dt>
                  <div className="absolute rounded-md bg-indigo-500 p-3">
                    {React.createElement(
                      IconMap['DevicePhoneMobileIcon'],
                      { className: 'block h-6 w-6 text-white', 'aria-hidden': 'true' }
                    )}
                  </div>
                  <p className="ml-16 truncate text-1xl font-semibold text-gray-900">{app.title}</p>
                </dt>
                <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                  <p className="text-sm truncate font-medium text-gray-500">{app.description}</p>
                  <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                    <div className="text-sm">
                      Edit app <span className="sr-only">Edit app</span>
                    </div>
                  </div>
                </dd>
              </div>
            </Link>
          ))}

          <button
            id="create-new"
            type="button"
            onClick={() => setIsCreateAppDialogOpen(true)}
            className="hover:border-blue-500 hover:border-solid hover:text-blue-500 group w-full flex flex-col items-center justify-center rounded-md border-2 border-dashed border-slate-300 text-sm leading-6 text-slate-900 font-medium py-3 h-full"
            style={{ "minHeight": "8rem"}}
          >
            <svg className="group-hover:text-blue-500 mb-1 text-slate-400" width="20" height="20" fill="currentColor" aria-hidden="true">
              <path d="M10 5a1 1 0 0 1 1 1v3h3a1 1 0 1 1 0 2h-3v3a1 1 0 1 1-2 0v-3H6a1 1 0 1 1 0-2h3V6a1 1 0 0 1 1-1Z" />
            </svg>
            New app
          </button>

          {isCreateAppDialogOpen && (
            <CreateAside
              type="app"
              onClose={closeCreateAppDialog}
              onCreate={handleAppCreate}
              isDialogOpen={isCreateAppDialogOpen}
            />
          )}

        </dl>
      )}
    </div>
  );
}
