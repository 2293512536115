import React, { useCallback, useState, useMemo } from 'react';
import ReactFlow, { addEdge, MiniMap, Controls, Background, applyNodeChanges, applyEdgeChanges } from 'reactflow';
import 'reactflow/dist/style.css';
import CustomNode from './CustomNode'; 
import html2canvas from 'html2canvas';

// Initial nodes including the default node
const initialElements = [
  {
    id: '1',
    type: 'custom', 
    position: { x: 250, y: 5 },
    data: { title: 'Default Node', description: 'This node cannot be deleted', asset_type: 'App'},
    deletable: false, 
  },
];

const ReactFlowEditor = ({ app_metadata, appConfig, onAppConfigUpdate, onAddItem, onDeleteItem }) => {
  const [nodes, setNodes] = useState(initialElements);
  const [edges, setEdges] = useState([]);
  const [nodeId, setNodeId] = useState(2);

  // Function to add and connect a new custom node from Tooltip
  const addNewCustomNode = useCallback((title, description, asset_type) => {
    const newNodeId = `node-${nodeId}`;
    const newNode = {
      id: newNodeId,
      type: 'custom',
      data: { title: title || nodeId, description: description || '', asset_type: asset_type || 'App'},
      position: { x: Math.random() * 500, y: Math.random() * 500 },
    };
  
    const newEdge = {
      id: `e1-${newNodeId}`,
      source: '1',
      target: newNodeId,
      animated: true, // Set to false for non-animated edges
      style: { stroke: 'black', strokeDasharray: '5,5' }, // Dashed line (5px dash, 5px gap)
    };
  
    setNodes((nds) => [...nds, newNode]);
    setEdges((eds) => [...eds, newEdge]);
    setNodeId((prevId) => prevId + 1);
  }, [nodeId]);

  const onNodesChange = useCallback((changes) => {
    setNodes((nds) => applyNodeChanges(changes, nds));
  }, []);

  const onEdgesChange = useCallback((changes) => {
    setEdges((eds) => applyEdgeChanges(changes, eds));
  }, []);

  const onConnect = useCallback((params) => {
    setEdges((eds) => addEdge(params, eds));
  }, []);

  // Memoize nodeTypes to prevent recreation on every render
  const nodeTypes = useMemo(() => ({
    custom: (props) => (
      <CustomNode
        {...props}
        app_metadata={app_metadata}
        appConfig={appConfig}
        onAppConfigUpdate={onAppConfigUpdate}
        onAddItem={onAddItem}
        onDeleteItem={onDeleteItem}
        addNewCustomNode={addNewCustomNode} 
      />
    ),
  }), [app_metadata, appConfig, onAppConfigUpdate, onAddItem, onDeleteItem, addNewCustomNode]);

  const downloadAsImage = () => {
    const element = document.querySelector('.react-flow');
    html2canvas(element).then((canvas) => {
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png');
      link.download = 'react-flow-diagram.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  return (
    <div className="flex" style={{ height: '100vh' }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange} 
        onEdgesChange={onEdgesChange} 
        onConnect={onConnect} 
        nodeTypes={nodeTypes}
        fitView
        className="react-flow"
        connectionLineType="straight"
      >
      <MiniMap nodeColor={(node) => (node.id === '1' ? '#ffcc00' : '#00ccff')} />
      <Controls />
        <Background color="#aaa" gap={16} />
      </ReactFlow>
      <button 
        onClick={downloadAsImage}
        className="absolute top-4 right-4 bg-blue-500 text-white px-4 py-2 rounded"
      >
        Download as Image
      </button>
    </div>
  );
};

export default ReactFlowEditor;