import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { ChevronRightIcon, PencilIcon } from '@heroicons/react/20/solid';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import ReusableModal from '../components/editor_components/ReusableModal/ReusableModal';

export default function ProductEditor({ context }) {
    const { uid } = useParams();
    const baseURL = window.location.hostname === "localhost" ? "http://localhost:3000" : process.env.REACT_APP_URL;

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [product, setProduct] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const [currentField, setCurrentField] = useState(null);
    const [fieldValues, setFieldValues] = useState({});
    const [isSaving, setIsSaving] = useState(false);
    const [generalError, setGeneralError] = useState('');
    const [isTextArea, setIsTextArea] = useState(false);

    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [isVariantModalOpen, setVariantModalOpen] = useState(false);
    const [variantFieldValues, setVariantFieldValues] = useState({ price: '', stock_quantity: '', sku: '' });

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const token = Cookies.get('auth_token');
                if (!token) {
                    throw new Error('No authentication token found');
                }
                const response = await axios.get(`/api/product/${uid}`, {
                    headers: { Authorization: `Token ${token}` },
                });
                setProduct(response.data);
            } catch (error) {
                setError(error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [uid]);

    const handleEditClick = (field, isTextArea = false) => {
        setCurrentField(field);
        setFieldValues({ [field]: product[field] });
        setIsTextArea(isTextArea);
        setModalOpen(true);
    };

    const handleVariantUpdate = (variant) => {
        setVariantFieldValues(variant);
        setVariantModalOpen(true);
    };

    const handleAddVariant = () => {
        setVariantFieldValues({ price: '', stock_quantity: '', sku: '' });
        setVariantModalOpen(true);
    };

    const handleDeleteVariant = (variant) => {
        setVariantFieldValues(variant);
        setDeleteModalOpen(true);
    };

    const handleSave = async () => {
        setIsSaving(true);
        setGeneralError('');

        try {
            const token = Cookies.get('auth_token');
            const payload = { ...fieldValues };
            const response = await fetch(`/api/product/${uid}/update`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Authorization: `Token ${token}` },
                body: JSON.stringify(payload),
            });
            if (!response.ok) throw new Error('Failed to save. Please try again.');
            const updatedProduct = await response.json();
            setProduct(updatedProduct);
            setModalOpen(false);
        } catch (error) {
            setGeneralError(error.message || 'Failed to save. Please try again.');
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <>
            <main className="lg:pl-72">
                <div className="">
                    <div className="px-4 py-6 sm:px-6 lg:px-8 lg:py-6">

                        {/* Breadcrumb placeholder */}
                        <nav aria-label="Breadcrumb">
                            <ol role="list" className="flex items-center space-x-4">
                                <li>
                                    <a href="/m/products" className="text-gray-400 hover:text-gray-500">
                                        <span className="text-sm font-medium text-gray-500 hover:text-gray-700">Products</span>
                                    </a>
                                </li>
                                <li className="flex items-center">
                                    <ChevronRightIcon className="h-5 w-5 text-gray-400" />
                                    <a href="#" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                                        {isLoading ? "Loading" : product?.title}
                                    </a>
                                </li>
                            </ol>
                        </nav>


                        <div className="mt-4">
                            {isLoading ? (
                                <h1 className="text-base font-semibold text-gray-900">Loading...</h1>
                            ) : error ? (
                                <h1 className="text-base font-semibold text-red-600">Error loading product</h1>
                            ) : (
                                <>
                                    <div className="mt-4 flex flex-col lg:flex-row lg:items-stretch lg:space-x-6 h-full bg-white border p-4 rounded-md">
                                        {/* Image section */}
                                        <div className="w-full lg:w-1/2 flex-grow">
                                            {product?.images.length > 0 ? (
                                                <TabGroup className="flex flex-col-reverse">
                                                    <TabList className="grid grid-cols-3 gap-6 justify-center">
                                                        {product?.images.map((image, index) => (
                                                            <Tab key={index}>
                                                                <img alt="" src={image.image_url} className="max-h-48 w-full object-cover object-center rounded-md" />
                                                            </Tab>
                                                        ))}
                                                    </TabList>
                                                    <TabPanels className="aspect-h-1 aspect-w-1 w-full flex-grow flex justify-center items-center mb-4">
                                                        {product?.images.map((image, index) => (
                                                            <TabPanel key={index}>
                                                                <img alt="" src={image.image_url} className="max-h-96 object-cover object-center rounded-md" />
                                                            </TabPanel>
                                                        ))}
                                                    </TabPanels>
                                                </TabGroup>
                                            ) : (
                                                <p>No images available.</p>
                                            )}
                                        </div>

                                        {/* Text section */}
                                        <div className="w-full lg:w-1/2 mt-4 lg:mt-0 flex-grow flex flex-col h-full">
                                            <div className="px-4 py-5 sm:p-6 flex-grow border rounded-md">
                                                <div className="flex items-center justify-between">
                                                    {/* Title */}
                                                    <h3 className="text-base font-semibold leading-7 text-gray-900">{product?.title}</h3>
                                                    <button
                                                        type="button"
                                                        onClick={() => handleEditClick('title')}
                                                        className="relative -mr-2 flex h-8 w-8 items-center justify-center rounded-full bg-white text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                                    >
                                                        <span className="absolute -inset-1.5" />
                                                        <PencilIcon aria-hidden="true" className="h-5 w-5" />
                                                        <span className="sr-only">Add description</span>
                                                    </button>          
                                                </div>
                                                {/* Description */}
                                                <div className="flex items-center justify-between mt-4">
                                                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">{product?.description}</p>
                                                    <button
                                                        type="button"
                                                        onClick={() => handleEditClick('description', true)}
                                                        className="relative -mr-2 flex h-8 w-8 items-center justify-center rounded-full bg-white text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                                    >
                                                        <span className="absolute -inset-1.5" />
                                                        <PencilIcon aria-hidden="true" className="h-5 w-5" />
                                                        <span className="sr-only">Add description</span>
                                                    </button>
                                                </div>

                                                {/* Product Variants List */}
                                                <div className="mt-4">
                                                    <ul role="list" >
                                                        {product?.variants.length > 0 ? (
                                                            product.variants.map((variant, index) => (
                                                                <li key={index} className="flex justify-between items-center p-4 border rounded-md my-2">
                                                                    <div className="flex space-x-6">
                                                                        <div>
                                                                            <p className="text-sm font-medium text-gray-900">Price: {variant.price}</p>
                                                                        </div>
                                                                        <div>
                                                                            <p className="text-sm font-medium text-gray-900">Stock: {variant.stock_quantity}</p>
                                                                        </div>
                                                                        <div>
                                                                            <p className="text-sm font-medium text-gray-900">SKU: {variant.sku || 'No SKU'}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex space-x-4">
                                                                        <button className="font-semibold text-indigo-600 hover:text-indigo-900" onClick={() => handleVariantUpdate(variant)}>
                                                                            Update
                                                                        </button>
                                                                        <button className="font-semibold text-red-600 hover:text-red-900" onClick={() => handleDeleteVariant(variant)}>
                                                                            Delete
                                                                        </button>
                                                                    </div>
                                                                </li>
                                                            ))
                                                        ) : (
                                                            <li className="py-4">
                                                                <p className="text-sm text-gray-500">No variants available.</p>
                                                            </li>
                                                        )}
                                                    </ul>

                                                    <button type="button" className="mt-4 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" onClick={handleAddVariant}>
                                                        <span aria-hidden="true">+</span> Create variation
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </main>

            {/* Title and Description Edit Modal */}
            <ReusableModal
                isOpen={isModalOpen}
                closeModal={() => setModalOpen(false)}
                title={`Edit ${currentField}`}
                fieldValues={fieldValues}
                setFieldValues={setFieldValues}
                handleSave={handleSave}
                isSaving={isSaving}
                generalError={generalError}
                isTextArea={isTextArea} // Conditional textarea for description
            />

            {/* Variant Edit/Add Modal */}
            <ReusableModal
                isOpen={isVariantModalOpen}
                closeModal={() => setVariantModalOpen(false)}
                title="Edit Variant"
                fieldValues={variantFieldValues}
                setFieldValues={setVariantFieldValues}
                handleSave={() => { }}  // Implement variant save logic
                isSaving={false}
                generalError={generalError}
            />

            {/* Delete Variant Modal */}
            <ReusableModal
                isOpen={isDeleteModalOpen}
                closeModal={() => setDeleteModalOpen(false)}
                title="Delete Variant"
                handleSave={() => { }}  // Implement variant delete logic
                isSaving={false}
                generalError={generalError}
                isDelete
            />
        </>
    );
}
