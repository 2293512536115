import React, { Fragment, useState } from 'react'
import Cookies from 'js-cookie';
import { Dialog, Transition } from '@headlessui/react'
import { IconMap } from '../../config/IconMap';
import ImageUploader from '../ImageUploader/ImageUploader';

export default function CreateAside({ type, onClose, onCreate, isDialogOpen }) {

    const authToken = Cookies.get('auth_token');
    const [formData, setFormData] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value.length <= 20) {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleImageFileChange = async (file) => {
        setLoading(true); // Set loading to true when the image is being uploaded
        try {
            const uploadedImageUrl = await ImageUploader.uploadToS3(file); // Replace with actual image upload logic
            setProductData({
            ...productData,
            image: uploadedImageUrl, // Store the uploaded image URL
            });
            setLoading(false); // Set loading to false after the image is uploaded
        } catch (error) {
            setLoading(false);
            setErrorMessage('Error uploading image.');
        }
    };

    // Product data
    const [productData, setProductData] = useState({
        title: '',
        description: '',
        price: '',
        sku: '',
        stock_quantity: '',
        image: '',
    });

    const handleProductChange = (e) => {
        const { name, value, files } = e.target;

        if (name === 'title') {
            if (value.length >= 5 && value.length <= 50) {
                const titlePattern = /^[a-zA-Z0-9\s\-,.!?]+$/;
                if (titlePattern.test(value)) {
                    setProductData({
                        ...productData,
                        [name]: value,
                    });
                    setErrorMessage(null);  // Clear any previous error message
                } else {
                    setErrorMessage('Title can only contain letters, numbers, spaces, hyphens, and basic punctuation (. , ? !).');
                }
            } else {
                setErrorMessage('Title must be between 5 and 50 characters.');
            }
        } else {
            // Handle other fields
            setProductData({
                ...productData,
                [name]: value,
            });
        }

        if (name === 'image') {
            if (files && files.length > 0) {
                setProductData({
                    ...productData,
                    [name]: files[0],  
                });
            }
        } else if (name === 'price') {
            const price = parseFloat(value).toFixed(2);  
            if (!isNaN(price) && price <= 10000) {
                setProductData({
                    ...productData,
                    [name]: price,
                });
            }
        } else if (name === 'stock_quantity') {
            const quantity = parseInt(value, 10);  
            if (!isNaN(quantity) && quantity <= 100000) {
                setProductData({
                    ...productData,
                    [name]: quantity,
                });
            }
        } else {
            setProductData({
                ...productData,
                [name]: value,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (loading) return;

        setErrorMessage(null);
        setLoading(true);

        let apiEndpoint, successMessage, dataToSend;
        switch (type) {
            case 'product':
                // Ensure that the required fields are filled in
                if (!productData.title || productData.title.trim() === '') {
                    setErrorMessage('Product title is required.');
                    setLoading(false);
                    return;
                }

                if (!productData.description || productData.description.trim() === '') {
                    setErrorMessage('Product description is required.');
                    setLoading(false);
                    return;
                }

                // Initialize FormData for file and product data submission
                dataToSend = new FormData();

                // Append required product fields
                dataToSend.append('title', productData.title);
                dataToSend.append('description', productData.description);

                // Append optional fields (SKU, price, stock quantity)
                if (productData.sku) {
                    dataToSend.append('sku', productData.sku);
                }

                if (productData.price) {
                    dataToSend.append('price', productData.price);
                }

                if (productData.stock_quantity) {
                    dataToSend.append('stock_quantity', productData.stock_quantity);
                }

                // Append image file if provided (optional)
                if (productData.image) {
                    dataToSend.append('image', productData.image);
                }

                apiEndpoint = '/api/create-product';
                successMessage = 'New product created successfully.';
                break;

            case 'form':
                if (!formData.title || formData.title.trim() === '') {
                    setErrorMessage('Asset name is required.');
                    return;
                }
                apiEndpoint = '/api/create-form';
                successMessage = 'New form created successfully.';
                dataToSend = JSON.stringify(formData);
                break;
            case 'page':
                apiEndpoint = '/api/create-page';
                successMessage = 'New page created successfully.';
                dataToSend = JSON.stringify(formData);
                break;
            case 'app':
            default:
                apiEndpoint = '/api/create-app';
                successMessage = 'New app created successfully.';
                dataToSend = JSON.stringify(formData);
                break;
        }
        try {
            const response = await fetch(apiEndpoint, {
                method: 'POST',
                body: dataToSend,
                headers: {
                    'Authorization': `Token ${authToken}`,
                    ...(type !== 'product' && { 'Content-Type': 'application/json' }),  
                },
            });
            if (response.ok) {
                const data = await response.json();
                onCreate(data);
                onClose();
            } else {
                const errorData = await response.json();
                if (response.status === 403 && errorData.error === 'app-limit-reached') {
                    setErrorMessage(
                        <>
                            You have reached the maximum number of apps allowed under your current plan. Please view{' '}
                            <a className="underline" href="https://ourapp.studio/#pricing" target="_blank" rel="noopener noreferrer">
                                our website for more information
                            </a>.
                        </>
                    );
                } else {
                    setErrorMessage('Request failed. Please try again.');
                }
                console.error('Request failed with status:', response.status);
            }
        } catch (error) {
            setErrorMessage('An error occurred. Please try again.');
            console.error('Error:', error);
        } finally {
            setLoading(false); 
        }
    };

    return (
        <Transition.Root show={isDialogOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={onClose}>
                <div className="fixed inset-0" />
                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                            <Transition.Child
                                as={Fragment}
                                enter="transition ease-in-out duration-300 transform"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                                    <form onSubmit={handleSubmit} className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                                        {/* Conditional rendering based on type */}
                                        {(type === 'form' || type === 'page') && (
                                            <div className="flex-1">

                                                {/* Header */}
                                                <div className="bg-indigo-800 px-4 py-6 sm:px-6">
                                                    <div className="flex items-start justify-between space-x-3">
                                                        <div className="space-y-1">
                                                            <Dialog.Title className="text-base font-semibold leading-6 text-white">
                                                                New {type}
                                                            </Dialog.Title>
                                                            <p className="text-sm text-gray-50">
                                                                Get started by filling in the information below to create your new {type}.
                                                            </p>
                                                        </div>
                                                        <div className="flex h-7 items-center">
                                                            <button
                                                                type="button"
                                                                className="relative text-gray-400 hover:text-gray-500"
                                                                onClick={onClose}
                                                            >
                                                                <span className="absolute -inset-2.5" />
                                                                <span className="sr-only">Close panel</span>
                                                                {React.createElement(
                                                                    IconMap['XMarkIcon'],
                                                                    { className: 'block h-6 w-6', 'aria-hidden': 'true' }
                                                                )}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Divider container */}
                                                <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                                                    {/* Project name */}
                                                    <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                                        <div>
                                                            <label
                                                                htmlFor="title"
                                                                className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                                            >
                                                                Form name
                                                            </label>
                                                        </div>
                                                        <div className="sm:col-span-2">
                                                            <input
                                                                required
                                                                type="text"
                                                                name="title"
                                                                id="title"
                                                                maxLength={20}
                                                                value={formData.title}
                                                                onChange={handleChange}
                                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            />
                                                            <p className="text-xs italic mt-4">Maximum 20 characters</p>
                                                        </div>
                                                    </div>

                                                    {/* Project description */}
                                                    <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                                        <div>
                                                            <label
                                                                htmlFor="description"
                                                                className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                                            >
                                                                Description
                                                            </label>
                                                        </div>
                                                        <div className="sm:col-span-2">
                                                            <textarea
                                                                id="description"
                                                                name="description"
                                                                value={formData.description}
                                                                onChange={handleChange}
                                                                required
                                                                rows={3}
                                                                maxLength={20}
                                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            />
                                                            <p className="text-xs italic mt-4">Maximum 20 characters</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {type === 'app' && (
                                            <div className="flex-1">
                                                {/* Header */}
                                                <div className="bg-indigo-800 px-4 py-6 sm:px-6">
                                                    <div className="flex items-start justify-between space-x-3">
                                                        <div className="space-y-1">
                                                            <Dialog.Title className="text-base font-semibold leading-6 text-white">
                                                                New project
                                                            </Dialog.Title>
                                                            <p className="text-sm text-gray-50">
                                                                Get started by filling in the information below to create your new app.
                                                            </p>
                                                        </div>
                                                        <div className="flex h-7 items-center">
                                                            <button
                                                                type="button"
                                                                className="relative text-gray-400 hover:text-gray-500"
                                                                onClick={onClose}
                                                            >
                                                                <span className="absolute -inset-2.5" />
                                                                <span className="sr-only">Close panel</span>
                                                                {React.createElement(
                                                                    IconMap['XMarkIcon'],
                                                                    { className: 'block h-6 w-6', 'aria-hidden': 'true' }
                                                                )}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Divider container */}
                                                <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                                                    {/* Project name */}
                                                    <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                                        <div>
                                                            <label
                                                                htmlFor="title"
                                                                className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                                            >
                                                                App name
                                                            </label>
                                                        </div>
                                                        <div className="sm:col-span-2">
                                                            <input
                                                                required
                                                                type="text"
                                                                name="title"
                                                                id="title"
                                                                maxLength={20}
                                                                value={formData.title}
                                                                onChange={handleChange}
                                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            />
                                                            <p className="text-xs italic mt-4">Maximum 20 characters</p>
                                                        </div>
                                                    </div>

                                                    {/* Project description */}
                                                    <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                                        <div>
                                                            <label
                                                                htmlFor="description"
                                                                className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                                            >
                                                                Description
                                                            </label>
                                                        </div>
                                                        <div className="sm:col-span-2">
                                                            <textarea
                                                                id="description"
                                                                name="description"
                                                                value={formData.description}
                                                                onChange={handleChange}
                                                                required
                                                                rows={3}
                                                                maxLength={20}
                                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            />
                                                            <p className="text-xs italic mt-4">Maximum 20 characters</p>
                                                        </div>
                                                    </div>

                                                    {/* Privacy */}
                                                    <fieldset className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                                        <legend className="sr-only">Privacy</legend>

                                                        <div className="text-sm font-medium leading-6 text-gray-900" aria-hidden="true">
                                                            Privacy
                                                        </div>

                                                        <div className="space-y-5 sm:col-span-2">
                                                            <div className="space-y-5 sm:mt-0">
                                                                <div className="relative flex items-start">
                                                                    <div className="absolute flex h-6 items-center">
                                                                        <input
                                                                            id="public-access"
                                                                            name="privacy"
                                                                            aria-describedby="public-access-description"
                                                                            type="radio"
                                                                            maxLength={20}
                                                                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                                            defaultChecked
                                                                        />
                                                                    </div>
                                                                    <div className="pl-7 text-sm leading-6">
                                                                        <label htmlFor="public-access" className="font-medium text-gray-900">
                                                                            Intended for customers (public)
                                                                        </label>
                                                                        <p id="public-access-description" className="text-gray-500">
                                                                            Public web users will be able to see your app and interact with your configured services.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </div>

                                                {errorMessage && (
                                                    <div className="p-4 m-4 text-sm text-gray-700 bg-blue-100 rounded-lg" role="alert">
                                                        {errorMessage}
                                                    </div>
                                                )}

                                            </div>

                                        )}

                                        {type === 'product' && (
                                            <div className="flex-1">
                                                <div className="bg-indigo-800 px-4 py-6 sm:px-6">
                                                    <div className="flex items-start justify-between space-x-3">
                                                        <div className="space-y-1">
                                                            <Dialog.Title className="text-base font-semibold leading-6 text-white">
                                                                New Product
                                                            </Dialog.Title>
                                                            <p className="text-sm text-gray-50">
                                                                Get started by filling in the information below to create your new product.
                                                            </p>
                                                        </div>
                                                        <div className="flex h-7 items-center">
                                                            <button
                                                                type="button"
                                                                className="relative text-gray-400 hover:text-gray-500"
                                                                onClick={onClose}
                                                            >
                                                                <span className="absolute -inset-2.5" />
                                                                <span className="sr-only">Close panel</span>
                                                                {React.createElement(
                                                                    IconMap['XMarkIcon'],
                                                                    { className: 'block h-6 w-6', 'aria-hidden': 'true' }
                                                                )}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Product Fields */}
                                                <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">

                                                    {/* Image Upload */}
                                                    <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                                        <ImageUploader imageFile={productData.image} setImageFile={handleImageFileChange} label="Product Image" />
                                                    </div>
                                                    

                                                    {/* Product Title */}
                                                    <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                                        <div>
                                                            <label
                                                                htmlFor="title"
                                                                className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                                            >
                                                                Product Title
                                                            </label>
                                                        </div>
                                                        <div className="sm:col-span-2">
                                                            <input
                                                                required
                                                                type="text"
                                                                name="title"
                                                                id="title"
                                                                value={productData.title}
                                                                onChange={handleProductChange}
                                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            />
                                                        </div>
                                                    </div>

                                                    {/* Description */}
                                                    <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                                        <div>
                                                            <label
                                                                htmlFor="description"
                                                                className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                                            >
                                                                Description
                                                            </label>
                                                        </div>
                                                        <div className="sm:col-span-2">
                                                            <textarea
                                                                id="description"
                                                                name="description"
                                                                value={productData.description}
                                                                onChange={handleProductChange}
                                                                required
                                                                rows={3}
                                                                maxLength={255}
                                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            />
                                                            <p className="mt-2 text-xs text-gray-500">Maximum 255 characters</p>
                                                        </div>
                                                    </div>

                                                    {/* Price */}
                                                    <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                                        <div>
                                                            <label
                                                                htmlFor="price"
                                                                className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                                            >
                                                                Price
                                                            </label>
                                                        </div>
                                                        <div className="sm:col-span-2">
                                                            <input
                                                                required
                                                                type="number"
                                                                step="0.01"  // Allow two decimal places
                                                                min="0"
                                                                max="10000"  // Set the maximum price to 10,000
                                                                name="price"
                                                                id="price"
                                                                value={productData.price}
                                                                onChange={handleProductChange}
                                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            />
                                                            <p className="mt-2 text-xs text-gray-500">
                                                                Please provide a price less than 10,000.
                                                            </p>
                                                        </div>
                                                    </div>

                                                    {/* Stock Quantity */}
                                                    <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                                        <div>
                                                            <label
                                                                htmlFor="stock_quantity"
                                                                className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                                            >
                                                                Stock Quantity (optional)
                                                            </label>
                                                        </div>
                                                        <div className="sm:col-span-2">
                                                            <input
                                                                type="number"
                                                                min="0"
                                                                max="100000"  // Set the maximum stock quantity to 100,000
                                                                step="1"  // No decimals allowed
                                                                name="stock_quantity"
                                                                id="stock_quantity"
                                                                value={productData.stock_quantity}
                                                                onChange={handleProductChange}
                                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            />
                                                            <p className="mt-2 text-xs text-gray-500">
                                                                Please enter a stock quantity less than 100,000. This field is optional.
                                                            </p>
                                                        </div>
                                                    </div>

                                                    {/* SKU (Optional) */}
                                                    <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                                        <div>
                                                            <label
                                                                htmlFor="sku"
                                                                className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                                                            >
                                                                SKU (optional)
                                                            </label>
                                                        </div>
                                                        <div className="sm:col-span-2">
                                                            <input
                                                                type="text"
                                                                name="sku"
                                                                id="sku"
                                                                value={productData.sku}
                                                                onChange={handleProductChange}
                                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            />
                                                            <p className="mt-2 text-xs text-gray-500">SKU is optional.</p>
                                                        </div>
                                                    </div>

                                                    {errorMessage && (
                                                        <div className="p-4 m-4 text-sm text-gray-700 bg-blue-100 rounded-lg" role="alert">
                                                            {errorMessage}
                                                        </div>
                                                    )}

                                                </div>
                                            </div>
                                        )}


                                        {/* Action buttons - all scenarios */}
                                        <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                                            <div className="flex justify-end space-x-3">
                                                <button
                                                    type="button"
                                                    className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                    onClick={onClose}
                                                    disabled={loading}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    onClick={handleSubmit}
                                                    disabled={loading}
                                                    className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                >
                                                    {loading ? 'Loading...' : 'Create'}
                                                </button>
                                            </div>
                                        </div>

                                    </form>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>

            </Dialog>
        </Transition.Root >
    );
}
