import { useState } from 'react';
import Cookies from 'js-cookie';
import { loadStripe } from '@stripe/stripe-js';

export default function DetailRows({ userData, context }) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentField, setCurrentField] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [generalError, setGeneralError] = useState('');
  const [loading, setLoading] = useState(false);

  const authToken = Cookies.get('auth_token');
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPEKEY);

  const statusStyle = userData.subscription_status === 'active'
    ? 'inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'
    : 'inline-flex items-center rounded-md bg-orange-50 px-2 py-1 text-xs font-medium text-orange-700 ring-1 ring-inset ring-orange-600/20';

  const capitalize = (text) => {
    if (text) {
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    }
    return '';
  };

  const formatDate = (dateString) => {
    if (!dateString) {
      return "No end date";
    }
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
  };

  const getFieldValue = (field) => {
    if (field.type === 'concat') {
      return field.targets.map(target => userData[target] || '').join(' ');
    }
    return userData[field.target] || field.placeholder;
  };

  const handleEditClick = (field) => {
    setCurrentField(field);
    if (field.type === 'concat') {
      setFirstName(userData[field.targets[0]] || '');
      setLastName(userData[field.targets[1]] || '');
    }
    setModalOpen(true);
  };

  const validateInput = (input) => {
    const regex = /^[A-Za-z]*$/;
    return regex.test(input) && input.trim() !== '';
  };

  const handleSave = async () => {
    setFirstNameError('');
    setLastNameError('');
    setGeneralError('');

    let payload = {};

    if (currentField?.type === 'concat') {
      if (!validateInput(firstName)) {
        setFirstNameError('Please enter a valid first name using only letters.');
        return;
      }

      if (!validateInput(lastName)) {
        setLastNameError('Please enter a valid last name using only letters.');
        return;
      }
      payload.first_name = firstName;
      payload.last_name = lastName;
    }

    setIsSaving(true);

    try {
      const response = await fetch('/api/update-profile/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${authToken}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Failed to save. Please try again.');
      }

      const updatedData = await response.json();

      if (currentField?.type === 'concat') {
        userData[currentField.targets[0]] = updatedData.first_name;
        userData[currentField.targets[1]] = updatedData.last_name;
      }

      setModalOpen(false);
    } catch (error) {
      setGeneralError(error.message || 'Failed to save. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };


  const handleManagePayments = async () => {
    setLoading(true);
    setGeneralError('');

    try {
      // Await the stripe object
      const stripe = await stripePromise;

      if (userData.subscription_type === 'Basic') {
        const response = await fetch('/api/upgrade-premium/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${authToken}`,
          },
          body: JSON.stringify({ price_id: process.env.REACT_APP_PREMIUM_MONTHLY_PRICE_ID }), // Use the price ID from env
        });

        const data = await response.json();
        if (response.ok) {
          // Redirect to Stripe Checkout using the Stripe object
          const { error } = await stripe.redirectToCheckout({ sessionId: data.id });
          if (error) {
            setGeneralError(error.message || 'Failed to redirect to checkout.');
          }
        } else {
          setGeneralError(data.error || 'Failed to create checkout session.');
        }
      } else {
        const response = await fetch('/api/create-session/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${authToken}`
          },
        });

        const data = await response.json();
        if (response.ok) {
          window.location.href = data.url;
        } else {
          setGeneralError(data.error || 'Failed to create session.');
        }
      }
    } catch (err) {
      setGeneralError('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="sm:w-full mt-6">
        <dl className="space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          {context.SCHEMA.values.map((field, index) => (
            <div key={index} className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">{field.field}</dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <span>{getFieldValue(field)}</span>
                {field.editable && (
                  <button
                    type="button"
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                    onClick={() => handleEditClick(field)}
                  >
                    Edit
                  </button>
                )}
              </dd>
            </div>
          ))}
        </dl>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-gray-400 duration-1000 ease-in-out bg-opacity-75 transition-opacity">
          <div className="relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div className="bg-white p-6 shadow-lg">
              <h3 className="text-base font-semibold leading-6 text-gray-900">Edit {currentField?.field}</h3>
              {currentField?.type === 'concat' && (
                <>
                  <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700">First Name</label>
                    <input
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      className="mt-1 w-full p-2 border border-gray-300 rounded-md"
                    />
                    {firstNameError && <p className="mt-2 text-sm text-red-600">{firstNameError}</p>}
                  </div>
                  <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700">Last Name</label>
                    <input
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      className="mt-1 w-full p-2 border border-gray-300 rounded-md"
                    />
                    {lastNameError && <p className="mt-2 text-sm text-red-600">{lastNameError}</p>}
                  </div>
                </>
              )}
              {generalError && <p className="mt-2 text-sm text-red-600">{generalError}</p>}
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button
                className={`inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 sm:ml-3 sm:w-auto ${isSaving ? 'cursor-not-allowed opacity-50' : ''}`}
                onClick={handleSave}
                disabled={isSaving}
              >
                {isSaving ? 'Saving...' : 'Save'}
              </button>

              <button
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                onClick={() => setModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {process.env.REACT_APP_SAAS_ENABLED === 'true' && (
        <div className="pt-16">
          <h2 className="sr-only">Subscription</h2>
          <h1 className="text-base font-semibold text-gray-900">Subscriptions</h1>
          <p className="mt-1 text-sm text-gray-500">
            You can manage your account subscription via 'Manage payments' below.
          </p>
          <div className="mt-6 border-t border-gray-200">
            <div className="mt-6 sm:w-full md:w-1/2 rounded-lg bg-white shadow-sm ring-1 ring-gray-900/5">
              <dl className="flex flex-wrap">
                <div className="flex-auto pl-6 pt-6">
                  <dd className="mt-1 text-base font-semibold leading-6 text-gray-900">{capitalize(userData.subscription_type)} plan</dd>
                </div>
                <div className="flex-none self-end px-6 pt-4">
                  <dt className="sr-only">Status</dt>
                  <dd className={statusStyle}>
                    {capitalize(userData.subscription_status)}
                  </dd>
                </div>
                <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 pt-6 px-2">
                  <dt className="flex-none">
                    <span className="sr-only">Your plan</span>
                  </dt>
                  <dd className="text-sm font-medium leading-6 text-gray-900">
                    Billing period end: <br />
                    <strong>{formatDate(userData.subscription_end_date)}</strong>
                  </dd>
                </div>
              </dl>
              <div className="mt-6 border-t border-gray-900/5 px-6 py-6">
                <a
                  href="#"
                  onClick={handleManagePayments}
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  {userData.subscription_type === 'Basic' ? 'Upgrade to unlock more features' : 'Manage payments'} <span aria-hidden="true">&rarr;</span>
                </a>
                {loading && <p className="mt-2 text-sm text-gray-500">Redirecting to Stripe...</p>}
                {generalError && <p className="mt-2 text-sm text-red-600">{generalError}</p>}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}