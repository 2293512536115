import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { IconMap } from '../../config/IconMap';
import { Tab, TabGroup, TabList, TabPanel, TabPanels, RadioGroup } from '@headlessui/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function ProductModal({ uid, isOpen, closeModal, previewMode = false, addToCart, styleGuide }) {
  const [isVisible, setIsVisible] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [productData, setProductData] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null); // Track selected variant
  const [error, setError] = useState(null); // For variant selection errors

  useEffect(() => {
    if (isOpen && uid) {
      setIsMounted(true);
      setTimeout(() => {
        setIsVisible(true);
      }, 10);

      // Disable background scroll
      document.body.style.overflow = 'hidden';

      // Reset selected variant each time a new product modal opens
      setSelectedVariant(null); // Clear previous selection

      // Fetch product details
      fetch(`/api/shop/${uid}`)
        .then((response) => response.json())
        .then((data) => {
          setProductData(data);
          setIsLoading(false);

          // Auto-select the only variant if there's exactly one
          if (data.variants && data.variants.length === 1) {
            setSelectedVariant(data.variants[0]);
          }
        })
        .catch((error) => {
          console.error('Error fetching product data:', error);
          setIsLoading(false);
        });

    } else {
      setIsVisible(false);
       // Re-enable background scroll
       document.body.style.overflow = '';
      setTimeout(() => {
        setIsMounted(false);
      }, 300);
    }
  }, [isOpen, uid]); // Trigger this effect when uid or isOpen changes

  if (!isMounted) return null;

  // Handle adding to cart only if a variant is selected
  const handleAddToCart = () => {
    if (productData.variants.length > 0 && !selectedVariant) {
      setError("Please select a variant before adding to cart.");
      return;
    }

    const itemToAdd = {
      uid: productData.uid,  // Ensure the product UID is passed
      title: productData.title,
      sku: selectedVariant.sku,  // Use the SKU for uniqueness
      price: selectedVariant.price,  // Price of the selected variant
      image_url: productData.images[0]?.image_url,  // Use the product image
      quantity: 1,  // Default quantity to 1
    };

    addToCart(itemToAdd);  // Add item to cart
    closeModal();  // Close modal after adding to cart
  };

  return (
    <div className="fixed inset-0 z-50 flex justify-center items-end">

      {/* Background overlay */}
      <div
        className="absolute inset-0 bg-black bg-opacity-50 transition-opacity duration-300"
        onClick={closeModal}
      ></div>

      {/* Modal content */}
      <div
        className={`bg-white w-full md:w-1/2 h-[90%] rounded-t-2xl relative z-50 transform transition-transform duration-300 ${isVisible ? 'translate-y-0 overflow-y-auto' : 'translate-y-full'}`}
      >
        {/* Close button */}
        <button
          onClick={closeModal}
          className="absolute top-4 left-4 text-white font-bold text-2xl rounded-full h-8 w-8 flex items-center justify-center bg-gray-700"
        >
          {React.createElement(IconMap['XMarkIcon'], { className: 'h-5 w-5 text-white', 'aria-hidden': 'true' })}
        </button>

        {isLoading ? (
          <Skeleton className="w-full h-64" />
        ) : (
          <>
            {/* Image Tab Group */}
            {productData.images.length > 1 ? (
              <TabGroup>
                <TabPanels>
                  {productData.images.map((image, index) => (
                    <TabPanel key={index}>
                      <img
                        src={image.image_url}
                        alt={image.alt_text || `Product Image ${index + 1}`}
                        className="w-full h-64 object-cover"
                      />
                    </TabPanel>
                  ))}
                </TabPanels>
                <TabList className="flex justify-center space-x-2 mt-2">
                  {productData.images.map((image, index) => (
                    <Tab key={index}>
                      <img src={image.image_url} alt={image.alt_text || `Image ${index + 1}`} className="h-20 w-20 object-cover" />
                    </Tab>
                  ))}
                </TabList>
              </TabGroup>
            ) : (
              <img
                src={productData.images[0].image_url}
                alt={productData.images[0].alt_text || "Product Image"}
                className="w-full h-64 object-cover"
              />
            )}

            <div className="p-2 m-2">
              <h2 className="text-2xl font-medium">{productData.title}</h2>
              <p className="mt-4 text-lg">{productData.description}</p>

              {/* Variant selection (if available) */}
              {productData.variants.length > 0 && (
                <div className="mt-4">
                  <RadioGroup value={selectedVariant} onChange={setSelectedVariant} className="space-y-4 mt-2">
                    {productData.variants.map((variant, index) => (
                      <RadioGroup.Option
                        key={index}
                        value={variant}
                        className={({ active, checked }) =>
                          classNames(
                            checked ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200',
                            'relative border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none'
                          )
                        }
                      >
                        {({ active, checked }) => (
                          <>
                            <span className="flex items-center w-full">
                              <span
                                className={classNames(
                                  checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300',
                                  'h-4 w-4 rounded-full border flex items-center justify-center'
                                )}
                                aria-hidden="true"
                              >
                                <span className="rounded-full bg-white h-1.5 w-1.5" />
                              </span>
                              <span className="ml-3 text-sm flex justify-between w-full items-center">
                                <div className="flex-grow">{variant.attributes.length > 0 ? `${variant.attributes.map((attr) => `${attr.title}: ${attr.value}`).join(", ")}` : "Default"} </div><div className="flex-shrink-0 text-right">Price: ${variant.price}</div>
                              </span>
                            </span>
                          </>
                        )}
                      </RadioGroup.Option>
                    ))}
                  </RadioGroup>
                  {error && <p className="text-red-600 mt-2">{error}</p>}
                </div>
              )}

              {/* Add to cart button */}
              <button
                onClick={handleAddToCart}
                disabled={productData.variants.length > 0 && !selectedVariant} // Disable if no variant is selected
                style={{
                  backgroundColor: styleGuide?.primary
                }}
                className={classNames(
                  'mt-6 bg-indigo-600 text-white py-3 px-6 shadow-lg transition duration-300 block w-full text-center rounded-full',
                  productData.variants.length > 0 && !selectedVariant ? 'opacity-50 cursor-not-allowed' : ''
                )}
                type="button"
              >
                  Add to cart
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
